export class AccordionListModule {
    constructor() {

        if ($(".accordionlist").length > 0) {
            this.init();
        }
    }

    setup() {
        $(".js-accordion__toggle").on("click", function (e) {
            e.preventDefault();
            var togglePath = $(this).attr("href");
            if ($(togglePath).hasClass("show")) {
                $(togglePath).removeClass("show");
                $(this).removeClass("showup");
            }
            else {
                $(togglePath).addClass("show");
                $(this).addClass("showup");
            }
            
        });
    }

    init() {
        this.setup();
    }

}