import { HelpersModule } from "../components/helpers/helpers";

const helpersModule = new HelpersModule();

export class EnquiryFormModule {
    constructor() {
        
        if ($("#customerenquiryform").length > 0) {
            this.init();
        }
    }

    getParsleyConfig() {
        return {
            errorsContainer: function (pEle) {
                var $err = pEle.$element.parents('.js-enquiry-form__tabs__content__inner__container__element').find('.error');
                return $err;
            },
            classHandler: function (el) {
                return el.$element.closest('.js-enquiry-form__tabs__content__inner__container__element');
            },
            uiEnabled: true
        };
    }
  
    setup() {
        
        var that = this;
        var $form = $(".js-enquiry-form");

        var $dobErrorRequiredContainer = $('.js-date-required-concatenate'),
            $dobContainer = $('.enquiry-form__tabs__content__inner__container__element--dob--required');


        $(".js-enquiry-form__submit").on("click", function (e) {

            e.preventDefault();

            var thisForm = $(this).parents(".js-enquiry-form");
            var day = parseInt($(thisForm).find(".js-dob-day-required").val(), 10);
            var month = parseInt($(thisForm).find(".js-dob-month-required").val(), 10);
            var year = parseInt($(thisForm).find(".js-dob-year-required").val(), 10);

            if (isNaN(day) || isNaN(month) || isNaN(year)) {

                $dobErrorRequiredContainer.text('Please complete this information.').show();
                $dobContainer.addClass('enquiry-form__tabs__content__inner__container__element--error');
                $dobContainer.addClass("error");


            } else {
                $dobErrorRequiredContainer.hide();
                $dobContainer.removeClass('enquiry-form__tabs__content__inner__container__element--error');
                $dobContainer.removeClass("error");
            }


            var parsleyForm = $(".js-enquiry-form").parsley(that.getParsleyConfig());

            if (parsleyForm.validate()) {

                var siteKey = $("#customerenquiryformtoken").val();

                grecaptcha.ready(function () {
                    grecaptcha.execute(siteKey, { action: 'enquiryform' }).then(function (token) {
                        $('[name="g-recaptcha-response"]').val(token);

                        var responseCode = {
                            gRecaptchaResponse: token
                        };

                        $.ajax({
                            type: "POST",
                            url: '/EnquiryCoreApi/ReCaptchaVerify',
                            data: JSON.stringify(responseCode),
                            contentType: "application/json; charset=utf-8",
                            dataType: "json"
                        }).done(function (data) {
                            if (data) {
                                var localform = $(".js-enquiry-form");
                                that.sendEnquiryForm(localform);
                            } else {
                                console.log('Failed to submit Form.');
                            }

                        }).fail(function (xhr, status, error) {
                            var errorMessage = xhr.status + ': ' + xhr.statusText
                            console.log('Failed to submit Form error = .' + errorMessage);
                        });


                    });
                });

            }


        });

        $form.find(".js-dob-day, .js-dob-month, .js-dob-year").on("change", function () {

             var thisForm = $(this).parents(".js-enquiry-form");

             $dobErrorRequiredContainer = $(thisForm).find('.js-date-required-concatenate');
             $dobContainer = $(thisForm).find('.enquiry-form__tabs__content__inner__container__element--dob--required');

             var day = parseInt($(thisForm).find(".js-dob-day").val(), 10);
             var month = parseInt($(thisForm).find(".js-dob-month").val(), 10);
             var year = parseInt($(thisForm).find(".js-dob-year").val(), 10);

             if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                 $(thisForm).find(".js-dob-concatenate").val(day + "/" + month + "/" + year);
             }

             if (isNaN(day) || isNaN(month) || isNaN(year)) {

                 $dobErrorRequiredContainer.text('Please complete this information.').show();
                 $dobContainer.addClass('enquiry-form__tabs__content__inner__container__element--error');


             } else {
                 $dobErrorRequiredContainer.hide();
                 $dobContainer.removeClass('enquiry-form__tabs__content__inner__container__element--error');

             }
        });

        window.Parsley.addValidator('date', {
            requirementType: 'string',
            validateString: function (value, requirements, parsleyInstance) {

                var $row = $(parsleyInstance.$element).parents(".js-enquiry-form__tabs__content__inner__container__element");

                var day = parseInt($row.find(".js-dob-day").val(), 10);
                var month = parseInt($row.find(".js-dob-month").val(), 10);
                var year = parseInt($row.find(".js-dob-year").val(), 10);

                if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {

                    var age = helpersModule.getAge(day, month, year);

                    return age > 17;
                }
            },
            messages: {
                en: 'You must be over 18 to submit an enquiry',
            }
        });

        window.Parsley.addValidator('customequalto', {
            requirementType: 'string',
            validateString: function (value, elem, parsleyInstance) {

                $(elem).on("keyup", _.debounce(function () {
                    parsleyInstance.validate();
                }, 100));

                if (value.length > 0 && $(elem).length > 0) {
                    return value.toLowerCase() === $(elem).val().toLowerCase();
                }
                return false;
            }
        });

    }

    sendEnquiryForm(thisForm) {

        var dobDay = thisForm.find(".js-dob-day").val() ? parseInt(thisForm.find(".js-dob-day").val(), 10) : false;
        var dobMonth = thisForm.find(".js-dob-month").val() ? parseInt(thisForm.find(".js-dob-month").val(), 10) : false;
        var dobYear = thisForm.find(".js-dob-year").val() ? parseInt(thisForm.find(".js-dob-year").val(), 10) : false;
        var dob = (dobDay && dobMonth && dobYear) ? dobYear + "/" + dobMonth + "/" + dobDay : 'Date of birth not set';
        var nearestHospitalName = '';
        var nearestHospitalId = '';
        var optinEmail = thisForm.find("input[name='optinEmail']").prop("checked");
        var optinSMS = thisForm.find("input[name='optinSms']").prop("checked");
        var optinPrivacy = thisForm.find("input[name='optinPrivacy']").prop("checked");
        var enquiryBestTimeToContact = ''; //thisForm.find('#enquiry-besttime-to-contact option:selected').text();
        var preferredConsultant = ''; //thisForm.find('#preferred-consultant').val();
        var selectedSpeciality = ''; //thisForm.find('#select-speciality');
        var selectedSpecialityValue = ''; //selectedSpeciality.val();

        var enquiryForm = {
            "enquirySource": thisForm.find('#hidden-enquiry-source').text(),
                "enquiryType": thisForm.find('#enquiry-type option:selected').text(),
                "fundingType": thisForm.find('#funding-type option:selected').text(),
                "enquiry": thisForm.find('#enquiry').val(),
                "title": thisForm.find('#title').val(),
                "firstName": thisForm.find('#first-name').val(),
                "surname": thisForm.find('#surname').val(),
                "dob": dob,
                "postcode": thisForm.find('#postcode').val(),
                "emailAddress": thisForm.find('#enquiry-email').val(),
                "emailAddressConfirm": thisForm.find('#enquiry-email-confirm').val(),
                "phoneNum": thisForm.find('#phone-number').val(),
                "enquiryFormOptin": thisForm.find('.enquiry-form__tabs__content__inner__container__element__checkbox').is(':checked'),
                "nodeId": thisForm.find('#hidden-node-id').text(),
                "isEnquiry": thisForm.find('#hidden-is-enquiry').text(),
                "nearestHospitalName": nearestHospitalName,
                "nearestHospitalId": nearestHospitalId,
                "perferredConsultant": preferredConsultant,
                "perferredSpeciality": selectedSpeciality,
                //"perferredDate": thisForm.find('#date').val(),
                //"perferredTime": thisForm.find('#select-time'),
                "isAppointment": thisForm.find('#hidden-is-appointment').text(),
                "isEvent": false,
                "enquiryFormOptinSMS": optinSMS,
                "enquiryFormOptinEmail": optinEmail,
                "enquiryFormOptinPrivacy": optinPrivacy,
                "enquiryBestTimeToContact": enquiryBestTimeToContact
        }

        //console.log(enquiryForm);

        $.ajax({
            type: "POST",
            url: '/enquirycoreapi/sendenquiry',
            data: JSON.stringify(enquiryForm),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).done(function (data) {
            // redirect to thank you page.
            window.location.href = "/thank-you";
        }).fail(function (xhr, status, error) {
            var errorMessage = xhr.status + ': ' + xhr.statusText
            alert('Error - ' + errorMessage);
        });


    }

    init() {
        this.setup();
    }
}