import { UrlHelperModule } from "./helpers/urlHelper";

var consultantsList;

export class HospitalConsultantsIntroModule {
    constructor() {
        if ($("#hospitalconsultantsintro").length > 0) {
            this.init();
        }
    }

    getConsultantData() {
        var _this = this;
        var remoteUrl = "/consultantcoreapi/GetConsultants";
        $.ajax({
            type: 'get',
            url: remoteUrl,
            contentType: "application/json; charsect=utf-8",
            dataType: 'json',
            success: function (response) {
                // maybe store the data globally
                consultantsList = response;
                if (consultantsList != undefined) {

                    _this.BuildSpecialityList(consultantsList.SpecialityList);

                }
            }
        });
    }

    BuildSpecialityList(specialities) {
        var _this = this;
        var data = `<div class="heading">Specialities</div><ul><li><a href='#' class='selected'>All</a></li>`;
        specialities.forEach(function (speciality) {
            data += `<li id='${speciality.Speciality}'><a href='#' class=''>${speciality.Speciality}</a></li>`;
        });
        data += `</ul>`;
        $("#specialities").html(data);

        _this.FilterConsultantList('');

        $("#specialities").on("click", "a", function (e) {
            e.preventDefault();
            var chosenSpeciality = $(this).text();

            $("#specialities li a ").removeClass("selected");

            $(this).addClass("selected");

            _this.FilterConsultantList(chosenSpeciality);


        });


    }

    FilterConsultantList(speciality) {

        $(".js-hospitalconsultantsintro--view-profile").html();
        $(".js-hospitalconsultantsintro--view-profile").hide();
        $(".js-hospitalconsultantsintro--consultants").show();
        

        var consultants = consultantsList.consultants;
        var liData = `<div class="heading">Consultants</div><ul>`;
        var profileData = ``;

        var con = [];
        consultants.forEach(function (consultant) {
            var found = false;
            consultant.Specialities.forEach(function (s) {
                if (s.toLowerCase() == speciality.toLowerCase()) {
                    con.push(consultant);
                }
            });
        });

        if (con.length > 0) {
            consultants = con;
        }

        consultants.forEach(function (consultant) {

            let consultantImageUrl = consultant.ProfileImageUrl;
            if (consultantImageUrl == '' || consultantImageUrl == undefined) {
                consultantImageUrl = '/dist/images/adhoc/noprofile.png';
            }

            liData += `<li><a href='#' class='js-profile-link' data-id="${consultant.NodeId}" data-consultantid="${consultant.NodeId}">${consultant.FullName}</a></li>`;

            profileData += `<div class="profile-image js-profile-image" data-id="${consultant.NodeId}" data-consultantid="${consultant.NodeId}">
                        <img src="${consultantImageUrl}" border="0" />
                    </div>`

        });
        
        liData += `</ul>`;

        $("#consultantsList").html(liData);
        $("#hospitalconsultantsintro--profiles").html(profileData);
        
        
    }

    ShowProfile(selectedId) {

        var _this = this;
        var profile = $.grep(consultantsList.consultants, function (e) { return e.NodeId == selectedId });
        if (profile.length > 0) {

            var _profile = profile[0];

            var cssSecretaryName = _profile.SecretaryName != null ? "" : "d-none";
            var cssSecretaryTelephone = _profile.SecretaryTelephone != null ? "" : "d-none";
            var cssSecretaryEmail = _profile.SecretaryEmail != null ? "" : "d-none";
            

            var data = `<div class="row no-gutters">
                    <div class="col-md-3">
                        <img src="${_profile.ProfileImageUrl}" class="img-fluid" />
                    </div>
                    <div class="col-md-5">
                        <div style="padding: 6px;">
                            <h3 class="medium">${_profile.FullName}</h3>
                            <p>${_profile.ShortDescription}</p>`;

                            if (_profile.SpecialInterests.length > 0) {
                                data += `<label><b>Special interests:</b></label>
                                <p>${_profile.SpecialInterests}</p>`;
                            }

                        data += `</div>
                    </div>
                    <div class="col-md-4">
                        <div class='secretary-box'>
                            <div class='row no-gutters'>
                                <div class='col-6 col-md-12 secretary-box--header'>
                                    Private Secretary:
                                </div>
                                <div class='col-6 col-md-12 secretary-box--content'>
                                    <div class='${cssSecretaryName}'>${_profile.SecretaryName}</div>
                                    <div class="tel ${cssSecretaryTelephone}"><a href="tel:${_profile.SecretaryTelephone}">${_profile.SecretaryTelephone}</a></div>
                                </div>
                                <div class='col-md-12 secretary-box--contact ${cssSecretaryEmail}'>
                                    <a href="mailto:${_profile.SecretaryEmail}">Contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
            data += `<a href='${_profile.Url}' class='button button--rounded-blue-transparent'>View profile</a>`;

            $(".js-hospitalconsultantsintro--view-profile").html(data);
            $(".js-hospitalconsultantsintro--consultants").hide();
            $(".js-hospitalconsultantsintro--view-profile").show();
        }
    }

    addGlobals() {

        var _this = this;

        
        $("#hospitalconsultantsintro").on("mouseenter", ".js-profile-link", function() {
            var $link = $(this);
            var currentId = $link.data("consultantid");
            $("#hospitalconsultantsintro--profiles").find(".js-profile-image[data-id='" + currentId + "']").addClass("selected");
        });

        $("#hospitalconsultantsintro").on("mouseleave", ".js-profile-link", function() {
            var $link = $(this);
            var currentId = $link.data("consultantid");
            $("#hospitalconsultantsintro--profiles").find(".js-profile-image[data-id='" + currentId + "']").removeClass("selected");
        });


        $("#hospitalconsultantsintro--profiles").on("mouseenter", ".js-profile-image", function() {
            var $link = $(this);
            var currentId = $link.data("consultantid");
            $("#hospitalconsultantsintro").find(".js-profile-link[data-id='" + currentId + "']").addClass("selected");
        });

        $("#hospitalconsultantsintro--profiles").on("mouseleave", ".js-profile-image", function() {
            var $link = $(this);
            var currentId = $link.data("consultantid");
            $("#hospitalconsultantsintro").find(".js-profile-link[data-id='" + currentId + "']").removeClass("selected");
        });

        $("#hospitalconsultantsintro").on("click", ".js-profile-link", function (e) {
            e.preventDefault();

            var $link = $(this);
            var currentId = $link.data("consultantid");

            $("#hospitalconsultantsintro").find(".js-profile-link").removeClass("selected");
            $link.addClass("selected");
            _this.ShowProfile(currentId);

        });

        $("#hospitalconsultantsintro").on("click", ".js-profile-image", function (e) {
            e.preventDefault();
            
            var $link = $(this);
            var currentId = $link.data("consultantid");

            $("#hospitalconsultantsintro").find(".js-profile-link").removeClass("selected");
            $("#hospitalconsultantsintro").find(".js-profile-link[data-id='" + currentId + "']").addClass("selected");

            _this.ShowProfile(currentId);
            
        });

    }


    init() {
        var _this = this;
        this.urlHelper = new UrlHelperModule();
        _this.getConsultantData();
        _this.addGlobals();
    }

}


