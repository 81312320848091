export class HomePageVideoModule {
    constructor() {
        var that = this;
        var $video = $('video');
        $video.autoplay = true;
        //$video.addEventListener('loadeddata', function () {
        //    $video.play();
        //});

        $video.attr("autoplay", true);
        $video.attr("loop", true);
        that.init();
    }
    init() {
        var $video = $('video'),
         $window = $(window);
        $(window).resize(function () {         
            $video.attr("autoplay", true);
            $video.attr("loop", true);

            var videoWidth = $video.width(),
                windowWidth = $window.width(),
                marginLeftAdjust = (windowWidth - videoWidth) / 2;

            $video.css({
               'marginLeft': marginLeftAdjust
            });
        }).resize();
    }
}



