export class VideoPlaylistModule {

    constructor() {

        if ($("#videoplaylist").length > 0) {
            this.init();
        }
    }

    init() {
    }

}