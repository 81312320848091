import { UrlHelperModule } from "./helpers/urlHelper";

export class ArticleListModule {
  
    constructor(helper) {
        let that = this;
        this.helper = helper;
        this.urlHelper = new UrlHelperModule();
        this.init();
    }

    events() {
        let that = this;
        $(".js-articles-filter-type").change(function () {
            var filter = $(".js-articles-filter-type option:selected").val();
            that.getArticles(filter);
        });
    }

    getArticles(filter) {

        var parent_id = document.getElementById('parent_id');

        var parent_id_value = (parent_id === null) ? "0" : parent_id.value;
        
        let r_baseUrl = "/articlescoreapi/Get?parent_id=" + parent_id_value + "&filter=" + filter;

        var dataContainer = $(".js-articles-list");
        var listingContainer = $(dataContainer).find(".article--list--results");
        var output = "";

        $(dataContainer).pagination({
            dataSource: r_baseUrl,
            ajax: {
                contentType: null
            },
            locator: 'Rows',
            totalNumberLocator: function (response) {
                return response.TotalRows;
            },
            prevText: 'Previous',
            nextText: 'Next',
            pageSize: 10,
            callback: function (data, pagination) {
                console.log(data);
                output = "";
                data.forEach(element => {
                    var button = `<a href='${element.SocialUrl}' target='_self' class='button button--rounded-blue-transparent'>View event</a>`;
                    if (element.ArticleType == "News") {
                        button = `<a href='${element.SocialUrl}' target='_self' class='button button--rounded-blue-transparent'>Read more</a>`;
                    }

                    var image = `<img src='${element.Thumbnail}' class='img-fluid'/>`;
                    if (element.Thumbnail == "") {
                        image = "";
                    }

                    var article = `

                        <div class='row article'>
                            <div class='col-md-2'>
                                <div class="date date--desktop">
                                    <div class="date--header">${element.Month}</div>
                                    <div class="date--body">
                                        <div class="date--body--day">${element.Day}</div>
                                        <div class="date--body--year">${element.Year}</div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-md-7 body'>
                                <div class='body--type'>${element.ArticleType}</div>
                                <h2>${element.Title}</h2>
                                <p>${element.StandFirst}</p>`
                                + button + 
                            `</div>
                            <div class='col-md-3 thumbnail'>`
                                + image + 
                            `</div>
                        </div>

                        
                    `
                    output += article;
                });

                listingContainer.html(output);
            }
        });

    }

    init() {
        let that = this;
        that.events();
        that.getArticles();
    }
  
}