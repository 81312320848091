////import { HelpersModule } from "../components/helpers/helpers";

////const helpersModule = new HelpersModule();

export class LoginRegisterModule {
    constructor() {
        if ($("#loginregisterform").length > 0) {
            this.init();
        }
    }

    checkStrength(password) {

        var strength = 0;
        if (password.match(/\d/)) strength += 1;
        if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1;
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1;
        if (password.length > 10) strength += 1;

        return strength;

    }

    validateForm($form) {
        var that = this;
        var v = true;
        $form.find(".js-required").each(function () {
            var currentElement = $(this);
            var field = currentElement.find(".js-required-field").first();
            if (field.is(":checkbox")) { 
                if (!field.is(":checked")) {
                    currentElement.find(".field-inner--error").show();
                    field.addClass('enquiry-form__tabs__content__inner__container__element--error error');
                    currentElement.find(".error-message").show();
                    v = false;
                }
            }
            else if (!field.val()) {
                currentElement.find(".field-inner--error").show();
                field.addClass('enquiry-form__tabs__content__inner__container__element--error error');
                currentElement.find(".error-message").show();
                v = false;
            }

            // double check for confirmation email.
            if ($("#confirm-email").length > 0) {
                if ($("#confirm-email").val().length > 0 && $("#email").val() != $("#confirm-email").val()) {
                    $("#confirm-email-error").show();
                    v = false;
                }
                else {
                    $("#confirm-email-error").hide();
                }
            }


        }).promise().done(function () {
            console.log('validation complete - value = ' + v);
            if (v) {
                that.processForm($form);
            }

        });
    }

    processForm($form) {
        var siteKey = $("#recaptchatoken").val();

        grecaptcha.execute(siteKey, { action: 'submit' }).then(function (token) {

            $('[name="g-recaptcha-response"]').val(token);

            var responseCode = {
                gRecaptchaResponse: token
            };

            $.ajax({
                type: "POST",
                url: '/EnquiryCoreApi/ReCaptchaVerify',
                data: JSON.stringify(responseCode),
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }).done(function (data) {

                if (data) { 
                    $form.submit();
                } else {
                    console.log('ReCaptcha Verification failed.');
                    $('.js-recaptcha-challenge-error').addClass("d-none");
                }

            }).fail(function (xhr, status, error) {
                var errorMessage = xhr.status + ': ' + xhr.statusText
                console.log('ReCaptcha Failed to submit Form error = .' + errorMessage);
                $('.js-recaptcha-challenge-error').addClass("d-none");
            });


        }, function (reason) {
            $('.js-recaptcha-challenge-error').addClass("d-none");
        });


    }


    setup() {

        var that = this;
        var $form = $(".login-register-form");
        $(".error-message-strength").hide();

        $form.on("submit", function () {
            $('.js-recaptcha-challenge-error').addClass("d-none");
            $(".enquiry-form__button-container__spinner").show();
            $(".js-login-register-form__submit").hide();
        });

        $(".js-login-register-form__submit").on("click", function (e) {

            e.preventDefault();
            that.validateForm($form);
        });

        $("#confirm-password").on("keyup", function () {
            if ($("#password").val() != $("#confirm-password").val()) {
                $("#confirm-password-error").show();
            }
            else {
                $("#confirm-password-error").hide();
            }
        });

        $("#password").on("keyup", function () {
            if (that.checkStrength($("#password").val()) < 4) {
                $(".error-message-strength").show();
            }
            else {
                $(".error-message-strength").hide();
            }
        });

        $("#confirm-email").on("keyup", function () {
            if ($("#confirm-email").length > 0) {
                if ($("#confirm-email").val().length > 0 && $("#email").val() != $("#confirm-email").val()) {
                    $("#confirm-email-error").show();
                }
                else {
                    $("#confirm-email-error").hide();
                }
            }
        });

        $form.find(".js-required").each(function () {
            var currentElement = $(this);
            var field = currentElement.find(".js-required-field").first();
            field.on("keyup change", function () {

                if (field.is(":checkbox")) { // checkbox 
                    if (!field.is(":checked")) {
                        currentElement.find(".field-inner--error").show();
                        field.addClass('enquiry-form__tabs__content__inner__container__element--error error');
                        currentElement.find(".error-message").show();
                    }
                    else {
                        currentElement.find(".field-inner--error").first().hide();
                        field.removeClass('enquiry-form__tabs__content__inner__container__element--error');
                        field.removeClass('error');
                        currentElement.find(".error-message").hide();
                    }
                }
                else if (!field.val()) {
                    currentElement.find(".field-inner--error").show();
                    field.addClass('enquiry-form__tabs__content__inner__container__element--error error');
                    currentElement.find(".error-message").show();
                }
                else {
                    currentElement.find(".field-inner--error").hide();
                    field.removeClass('enquiry-form__tabs__content__inner__container__element--error');
                    field.removeClass('error');
                    currentElement.find(".error-message").hide();
                }

            });

        });

    }

    init() {
        this.setup();
    }

}