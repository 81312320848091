export class HeroModule {
    constructor() {
        
        if ($("#header-hero").length > 0) {
            this.init();
        }
    }

    init() {

        $(".js-hero--notice-bar--advert--selector").on("click", function (e) {

            if ($(".js-hero--notice-bar--advert--content").hasClass("is-on")) {
                $(".js-hero--notice-bar--advert--content").removeClass("is-on");
            }
            else {
                $(".js-hero--notice-bar--advert--content").addClass("is-on");
            }


        });


        //$("#header-hero").on("click", "#hero-banner", function () {

        //    console.log("Open: " + $(".hero-home__footer-banner--transparent").hasClass("open"));
        //    console.log("Close: " + $(".hero-home__footer-banner--transparent").hasClass("close"));

        //    if ($(".hero-home__footer-banner--transparent").hasClass("open")) {
        //        $(".hero-home__footer-banner--transparent").removeClass("open");
        //        $(".hero-home__footer-banner--transparent").addClass("close");
        //    }
        //    if ($(".hero-home__footer-banner--transparent").hasClass("close")) {
        //        $(".hero-home__footer-banner--transparent").removeClass("close");
        //        $(".hero-home__footer-banner--transparent").addClass("open");
        //    }
        //    else {
        //        $(".hero-home__footer-banner--transparent").addClass("open");
        //    }
            
            
        //});




    }


}