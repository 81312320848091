export class ConsultantFormSearch {

    constructor(helper) {
        this.initEvents();
    }

    disableSubmit() {
        //button--disabled
        $(".submitButton").attr("disabled", "disabled");
        $(".submitButton").addClass("button--disabled");
    }

    enableSubmit() {
        $(".submitButton").removeAttr("disabled");
        $(".submitButton").removeClass("button--disabled");
    }

    enableIfValidSubmit() {
        if ($(".searchTreatment").val().length > 0 || $(".searchConsultant").val().length > 0)
            this.enableSubmit();
        else
            this.disableSubmit();
    }

    initEvents() {

        let that = this;

        this.disableSubmit();

        const ajaxUrl = "/specialityCoreApi/GetAllApiTreatments";

        fetch(ajaxUrl)
            .then(response => response.json())
            .then(treatments => {
                const treatmentsArr = treatments.map(treatment => treatment.Name);

                $(".searchTreatment").autocomplete({
                    source: treatmentsArr
                });

            })
            .catch(err => console.log(err));


        $(".searchTreatment,.searchConsultant").on("keyup", function (e) {
            that.enableIfValidSubmit();
        });

    }

}