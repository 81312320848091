export class TreatmentOverviewModule {
  
    /*

    TODO - This bit doesn't compile, and gulp needs to be fixed to report errors.    
 
    $filter;
    $results;
    $specialities;
    $checkboxes;
    $clearButton;*/
  
    constructor(helper) {

        this.helper = helper;
        this.$filter = $(".treatmentoverview__filters");
        this.$filterFieldset = this.$filter.find("fieldset");
        this.$results = $(".treatmentoverview__results");
        this.$specialities = this.$results.find("> ul > li");
        this.$checkboxes = this.$filter.find("input[type='checkbox']");
        this.$clearButton = this.$filter.find(".clear-btn");

        this.initFilters();
        this.initEvents();
    }

    initFilters() {
        let speciality = this.helper.getQueryStringByName("speciality");

        if (speciality) {
            let $matchingCheckbox = this.$checkboxes.filter(function() {
                return $(this).val() === speciality;
            });
            
            if ($matchingCheckbox.length) {                
                $matchingCheckbox.prop("checked", true);
                this.$filterFieldset.addClass("show");
            }
            
        }
    }
  
    initEvents() {
        let that = this;
        this.$checkboxes.change(function() {
            that.filterResults(that.getFilters());
        });
    
        this.$clearButton.click(function() {
            that.clearFilters();
            that.filterResults(that.getFilters());
        });

        this.filterResults(this.getFilters());
    }
  
    clearFilters() {
        this.$checkboxes.prop("checked", false);
    }
  
    getFilters() {
        let $checked = this.$checkboxes.filter(":checked");
        let returnValue = $.makeArray($checked).map(f => $(f).val());
        return returnValue;
    }
  
    filterResults(objFilters) {
        if (objFilters.length) {
            this.$specialities.each(function() {
                let $speciality = $(this);
                let specialityId = $speciality.data("speciality");
                if (objFilters.includes(specialityId)) {
                    $speciality.removeClass("hidden");
                } else {
                    $speciality.addClass("hidden");
                }
            });
        } else {
            this.$specialities.removeClass("hidden");
        }
    }
  
}