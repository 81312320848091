import { UrlHelperModule } from "./helpers/urlHelper";
export class NavigationModule {
    constructor() {
        if ($(".navigation-bar--module").length > 0) {
            this.urlHelper = new UrlHelperModule();
            this.$speciality = $("#Speciality");
            this.init();
        }
        //this.$reset: $(".js-site-header__main__search__reset"),
        this.$prompt = $(".consultant-name-filter-menu__autocomplete__prompt");
        this.$warning = $(".consultant-name-filter-menu__autocomplete__promptmore");
        this.$results = $(".consultant-name-filter-menu__autocomplete__results");
    }

    setup() {
        let that = this;
        $(".js-navigation-consultant-name").hover(
            function () {
                var consultantid = $(this).data("id");
                var profile = $(".consultant-menu--profile").find("[data-id=" + consultantid + "]");
                $(".consultant-menu--profile .defaultText").hide();
                $(profile).show();

            }, function () {
                var consultantid = $(this).data("id");
                var profile = $(".consultant-menu--profile").find("[data-id=" + consultantid + "]");
                $(profile).hide();
                $(".consultant-menu--profile .defaultText").show();
            }
        );

        $("#consultant-name-filter-menu").focus(function (event) {
            var value = $(this).val();
            if (value.length === 0) {
                that.$warning.removeClass("is-on");
                that.$prompt.addClass("is-on");
                that.$results.removeClass("is-on");
            }
        });

        $(document).mouseup(function (e) {
            var container = $(".consultant-menu--profile");
            var that = this;
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".consultant-name-filter-menu__autocomplete__prompt").removeClass("is-on");
                $(".consultant-name-filter-menu__autocomplete__promptmore").removeClass("is-on");
                $(".consultant-name-filter-menu__autocomplete__results").removeClass("is-on");
            }
        });

        $("#consultant-name-filter-menu").keyup(function (event) {
            var value = $(this).val();

            if (event.keyCode === 13) {
                window.location.href = "/consultants/?consultant=" + value;
            }
            else {
                if (value.length < 3) {
                    that.$warning.addClass("is-on");
                    that.$prompt.removeClass("is-on");
                    that.$results.removeClass("is-on");
                } else {
                    that.$warning.removeClass("is-on");
                    that.$prompt.removeClass("is-on");
                    that.performSearch(value);
                }
            }
        });

        this.getSpecialities(function (data) {
            that.setSpecialities(data);
            that.$speciality.change(function () {
                window.location.href = "/consultants/?speciality=" + $(this).val();
            });
        });
    }

    init() {
        this.setup();
    }

    setSpecialities(specialities) {
        this.$speciality.empty();
        this.$speciality.append("<option value=''>Select speciality</option>");
        for (var speciality of specialities) {
            this.$speciality.append(`<option value='${speciality.Key}'>${speciality.Name}</option>`);
        }
    }

    getSpecialities(fnCallback) {
        let remoteUrl = "/specialitycoreapi/Get";
        $.ajax({
            type: 'get',
            url: remoteUrl,
            contentType: "application/json; charset=utf-8",
            dataType: 'json',
            success: function (response) {
                fnCallback(response);
            }
        });
    }

    performSearch(searchTerm) {

    var that = this;
    let endpoint = "/consultantcoreapi/Suggestions?query=" + encodeURIComponent(searchTerm);

    $.ajax({
        method: "GET",
        url: endpoint,
        contentType: "application/json; charset=utf-8",
        dataType: 'json',
    }).done(function (data) {
        that.results(data);
    });
    }

    results(apiResults) {
    var that = this;

    var templateString = '<div class="search-autocomplete">';
    templateString += '<div class="search-autocomplete__action-links">';
    templateString += '<ul>';
        for (var result of apiResults) {
            templateString += '<li><a href="' + result.Url + '">' + result.FullName + '</a></li>';
    }
    templateString += '</ul>';
    templateString += '</div>';
    templateString += '</div>';

    var html = templateString;

    if (apiResults.length > 0 || apiResults.length > 0) {
            this.$results.addClass("is-on").html(html);
    }
    else {
            this.$results.removeClass("is-on").html(html);
        }
    }
}