/**
 * Toggle module, in the style of Bootstrap's "Collapse" (https://getbootstrap.com/docs/4.0/components/collapse/)
 * but with the following advantages:
 * - Can toggle any class, not just "collapse"
 * - Works better with multiple of the same component on the one page (you pretty much had to use id selectors with Bootstrap)
 * - Uses jQuery selectors, not just class selectors
 *
 * After any 'show' transitions are complete, the module adds a 'done' class, which is useful for applying final styles eg. scroll bars.
 *
 * Configuration
 * --------------
 * data-shc-toggle: toggle this class
 * data-shc-target: jQuery selector used to find the element to toggle the class on
 * data-shc-container: optionally, restrict the target to be beneath this ancestor container (the container can also be the same as the target!)
 *
 * Examples
 * --------
 *
 * Example 1 - toggling the show class on an element with id:
 *
 * <a data-shc-toggle="show" data-shc-target="#destination">View transcript of this video</a>
 * <div id="destination"></div>
 *
 * Example 2 - toggling only within a container:
 *
 * <div class="transcript">
 *   <div class="transcript-text" id="transcript">
 *     <a data-shc-toggle="show" data-shc-target=".transcript-text" data-shc-container=".transcript">Hide video transcript</a>
 *   </div>
 * </div>
 *
 */
export class ToggleModule {
    
    /**
     * Instantiate the toggle module, which allows all elements with
     * a data-shc-toggle attribute to respond to clicks.
     */
    constructor() {

        let that = this;

        $("[data-shc-toggle]").on('click', function(evt) {
            evt.preventDefault();

            let $clickableElement = $(this);
            let strToggleClass = $clickableElement.data("shc-toggle");
            let strTargetSelector = $clickableElement.data("shc-target");
            let strContainerSelector = $clickableElement.data("shc-container");

            let $targetElement = strContainerSelector ?
                that.findIncludingSelf($clickableElement.closest(strContainerSelector), strTargetSelector) :
                $(strTargetSelector);
            
            $targetElement.one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function() {
                if ($targetElement.hasClass(strToggleClass)) {
                    $targetElement.addClass("done");
                } else {
                    $targetElement.removeClass("done");
                }
            });

            $targetElement.toggleClass(strToggleClass);

        });

    }

    findIncludingSelf($parentElement, strSelector) {
        return $parentElement.find(strSelector).addBack(strSelector);
    }

}