export class UrlHelperModule {
    constructor() {
        this.mackApi = false;
        this.apilocation = "";
        this.apilocation = document.querySelector("body").dataset.apilocation;
        if (this.apilocation == '' || this.apilocation == undefined) {
            this.apilocation = '';
        }

        this.init();
    }

    init() {
        let that = this;
        //comment this out on live
        if (location.hostname.indexOf('localhost') > -1) {
            that.apilocation = '';
            that.mockApi = true;
        }
    }


    getUrlParameter(sParam) {
        let sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return "";
    }

}

