import { UrlHelperModule } from "./helpers/urlHelper";

export class TestimonialsListModule {
  
    constructor(helper) {
        let that = this;
        this.helper = helper;
        this.urlHelper = new UrlHelperModule();
        this.init();
    }

    getTestimonials() {


        let r_baseUrl = "/testimonialscoreapi/Get?";
        console.log(r_baseUrl);

        var dataContainer = $(".js-testimonials-list");
        var listingContainer = $(dataContainer).find(".testimonial--list--results");
        var output = "";

        $(dataContainer).pagination({
            dataSource: r_baseUrl,
            ajax: {
                contentType: null
            },
            locator: 'Rows',
            totalNumberLocator: function (response) {
                return response.TotalRows;
            },
            prevText: 'Previous',
            nextText: 'Next',
            pageSize: 10,
            callback: function (data, pagination) {
                console.log(data);
                output = "";
                data.forEach(element => {

                    var button = ``;
                    if (!element.HideReadMoreButton) {
                        button = `<div class='col-12'><a href='${element.SocialUrl}' target='_self' class='button button--rounded-blue-transparent'>Read more</a></div>`;    
                    }

                    var testimonial = `
                        <div class='row testimonial'>
                            <div class='col-12'>
                                <div class='headerText quote'>
                                   ${element.StandFirst}
                                </div>
                                
                            </div>
                            <div class='col-12'>
                                <div class='subTitle'>
                                    ${element.SubTitle}
                                </div>
                            </div> `
                            + button + `
                        </div>
                    `
                    output += testimonial;
                });

                listingContainer.html(output);
            }
        });

    }

    init() {
        let that = this;
        that.getTestimonials();
    }
  
}