
export class RatingsSummaryModule {

    constructor() {
        if ($("#component-customer-ratings-summary").length > 0) {
            this.init();
        }
    }

    loadBars() {

        var summary = $("#component-customer-ratings-summary");
        var chart = $(summary).find(".customer-ratings--summary-chart");
        var barInners = $(chart).find(".bar--inner");
        $.each(barInners, function (i, obj) {
            var percent = $(obj).data("percent");
            //$(obj).width(percent);

            $(obj).animate({ width: percent }, 1000);

        });

    }

    init() {
        var _this = this;
        window.requestAnimationFrame(function () {
            _this.loadBars();
        });
        
    }
}