export class NewHeroModule {
    constructor() {

        if ($("#new-hero").length > 0) {
            this.init();
        }
    }

    init() {

        $(document).ready(function () {

            // Select and loop the container element of the elements you want to equalise
            $('.new-hero-box-container').each(function () {

                // Cache the highest
                var highestTitle = 0;
                var highestBody = 0;

                // Select and loop the elements you want to equalise
                $('.hero-box-text-title', this).each(function () {

                    // If this box is higher than the cached highest then store it
                    if ($(this).height() > highestTitle) {
                        highestTitle = $(this).height();
                    }

                });

                $('.hero-box-text-body', this).each(function () {

                    // If this box is higher than the cached highest then store it
                    if ($(this).height() > highestBody) {
                        highestBody = $(this).height();
                    }

                });

                // Set the height of all those children to whichever was highest
                $('.hero-box-text-title').css({ 'min-height': (highestTitle + 5) + 'px' });
                $('.hero-box-text-body').css({ 'min-height': (highestBody + 5) + 'px' });
            });

        });
    }


}