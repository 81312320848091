import { UrlHelperModule } from "./helpers/urlHelper";

export class ConsultantOverviewModule {

    constructor(helper) {
        this.helper = helper;
        this.urlHelper = new UrlHelperModule();
        this.$filter = $(".consultant-overview__filter");
        this.$specialityUlList = this.$filter.find("#filter-speciality-list");
        this.$consultantProfiles = $(".consultant-overview__profiles > ul");

        this.$filterSpeciality = this.urlHelper.getUrlParameter("speciality");
        this.$filterConsultantName = this.urlHelper.getUrlParameter("consultant");
        this.$filterTreatmentName = this.urlHelper.getUrlParameter("treatment"); 

        this.$filterConsultantNameCtrl = $("#consultant-name-filter");
        this.$filterTreatmentNameCtrl = $("#treatment-name-filter");

        //console.log("Url Filter Consultant Name Length = " + this.$filterConsultantName.length)
        //console.log("Url Filter Treatment Name Length = " + this.$filterTreatmentName.length)

        this.initEvents();
    }

    initEvents() {

        // Set the spinner
        $('#loadingDiv')
            .hide()  // Hide it initially
            .ajaxStart(function () {
                $(this).show();
            })
            .ajaxStop(function () {
                $(this).hide();
            });

        // Set defaults
        this.$filterConsultantNameCtrl.val(this.$filterConsultantName);
        this.$filterTreatmentNameCtrl.val(this.$filterTreatmentName);

        this.setKeyEvents();

        // Get Specialities from server for list
        this.getSpecialities();
    }

    // Get the Specialities and populate UL with on click events and Load the default consultants from the search.
    getSpecialities() {

        //console.log("Getting Specialities");

        let that = this;
        let remoteUrl = "/specialitycoreapi/Get";
        $.ajax({
            type: 'get',
            url: remoteUrl,
            contentType: "application/json; charset=utf-8",
            dataType: 'json',
            success: function (response) {

                //console.log("Getting Specialities Response");
                //console.log(response);
                
                that.setSpecialities(response);

                that.setOnClickEvents(); 
                
                that.rebuildFilterBar();

                that.getConsultants();
            }
        });
    }

    setSpecialities(specialities) {

        //console.log("Building Specialities Bar");

        this.$specialityUlList.empty();
        this.$specialityUlList.append(`<li><a class='filter-speciality-item' data-speciality='' value=''>All</a></li>`);
        for (const speciality of specialities) {
            this.$specialityUlList.append(`<li><a class='filter-speciality-item' data-speciality='${speciality.Key}' value='${speciality.Key}'>${speciality.Name}</a></li>`);
        }
    }

    setOnClickEvents() {
        //console.log("Setting on click events");
        let that = this;
        $("#filter-speciality-list").on("click", ".filter-speciality-item", function (e) {
            e.preventDefault();
            that.$filterSpeciality = $(this).data("speciality");

            //console.log("Clicked Speciality = " + that.$filterSpeciality);
            that.getConsultants();
        });

        $("#clearFilter").on("click", function (e) {
            e.preventDefault();
            that.clearFilters();
        })
    }

    clearFilters() {
        this.$filterConsultantNameCtrl.val("");
        this.$filterTreatmentNameCtrl.val("");

        this.$filterConsultantName = "";
        this.$filterTreatmentName = "";

        this.rebuildFilterBar();

        this.getConsultants();
    }

    rebuildFilterBar() {

        //console.log("Rebuild the filter Bar.")

        $(".filter-bar .filterItems").empty();
        $(".filter-bar").hide();

        if (this.$filterConsultantName.length > 0 || this.$filterTreatmentName.length > 0) {
            let items = ``;
            if (this.$filterConsultantName.length > 0) {
                items += `<span class="filterTitle">Consultant:</span><span>${this.$filterConsultantName}</span>`;
            }

            if (this.$filterTreatmentName.length > 0) {
                items += `<span class="filterTitle">Treatment:</span><span>${this.$filterTreatmentName}</span>`;
            }

            $(".filter-bar .filterItems").html(items);
            $(".filter-bar").show();
        }
    }

    getConsultants() {

        let that = this;

        //console.log("Getting the filtered consultants data")
        //console.log("Speciality Chosen = " + this.$filterSpeciality);
        //console.log("Consultant Chosen = " + this.$filterConsultantName);
        //console.log("Treatment Chosen = " + this.$filterTreatmentName);

        let urlParams = "?" + "speciality=" + this.$filterSpeciality + "&treatmentText=" + encodeURIComponent(this.$filterTreatmentName);
        let remoteUrl = "/consultantcoreapi/Get" + urlParams;

        //console.log(remoteUrl);

        $.ajax({
            type: 'get',
            url: remoteUrl,
            contentType: "application/json; charset=utf-8",
            dataType: 'json',
            success: function (response) {
                let filteredResults = that.filterResults(response);
                //console.log(filteredResults);
                that.buildConsultantsResults(filteredResults)
            }
        });

    }

    buildConsultantsResults(specialities) {

        this.$consultantProfiles.empty();

        for (let speciality of specialities) {

            if (!speciality.Name || speciality.Consultants.length == 0) {
                continue;
            }

            let profileData = "";
            for (const consultant of speciality.Consultants) {

                profileData =
                    profileData.concat(
                        `<a class="consultant-overview__profile_link" data-name="${consultant.FullName}" href="${consultant.Url}">
                            <div class="consultant">
                                <img src="${consultant.ImageUrl}" alt="A profile image of ${consultant.FullName}" />
                                <h2><span>${consultant.FullName}</span></h2>
                                <p>${consultant.Position}</p>
                                <p style="display:none">${consultant.Treatments}</p>
                            </div></a>`);
            }

            this.$consultantProfiles.append(`
                <li class="consultant-overview__profile_speciality" data-speciality="${speciality.Key}">
                    <p class="treatment-header">${speciality.Name}</p>
                    ${profileData}                    
                </li>`);
        }   


    }

    setKeyEvents() {

        //console.log("Setting the Key Events..");

        let that = this;
        let keyValid = false;

        this.$filterConsultantNameCtrl.on('keydown', function (e) {
            keyValid = that.testTextOnlyString(e);
            return keyValid;
        });

        this.$filterConsultantNameCtrl.on("keyup", function (e) {
            if (keyValid) {
                that.setFilteredResults();
                keyValid = false;
            }
        });

        this.$filterTreatmentNameCtrl.on('keydown', function (e) {
            keyValid = that.testTextOnlyString(e);
            return keyValid;
        });

        this.$filterTreatmentNameCtrl.on("keyup", function (e) {
            if (keyValid) {
                that.setFilteredResults();
                keyValid = false;
            }
        });

    }

    setFilteredResults() {
        this.$filterConsultantName = this.$filterConsultantNameCtrl.val();

        //console.log(this.$filterConsultantName);

        this.rebuildFilterBar();

        this.getConsultants();

    }

    filterResults(specialities) {

        let that = this;

        specialities.forEach(filterConsultants);
        specialities.forEach(filterTreatments);

        function filterConsultants(spec) {
            if (spec.Consultants != null && spec.Consultants.length > 0 && that.$filterConsultantName.length > 0) {
                spec.Consultants = $.grep(spec.Consultants, function (v) {
                    return (v.FullName.toUpperCase().indexOf(that.$filterConsultantName.toUpperCase()) > -1);
                });
            }
        }

        function filterTreatments(spec) {
            if (spec.Consultants != null && spec.Consultants.length > 0 && that.$filterTreatmentName.length > 0) {
                spec.Consultants = $.grep(spec.Consultants, function (v) {

                    let treatments = $.grep(v.Treatments, function (t) {
                        return (t.toUpperCase().indexOf(that.$filterTreatmentName.toUpperCase()) > -1);
                    });

                    return treatments.length > 0;

                });
            }
        }

        return specialities;
    }

    testTextOnlyString(e) {
        let keyPressRgEx = /^([a-zA-Z& .\-_])$/;
        return e.key.match(keyPressRgEx) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 36 || e.keyCode == 16 || (e.keyCode >= 37 && e.keyCode <= 40);
    }

}