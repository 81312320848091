export class VideoCarouselModule {

    constructor() {

        if ($("#component-video-carousel").length > 0) {
            this.properties();
            this.init();
        }
    }

    properties() {
        this.sliderSelector = "#component-video-carousel .video-carousel";
        this.slidesToShow = 3;
        this.slidesToScroll = 1;
        this.initialSlide = 0;
    }

    init() {
        this.loadSlick()
    }

    loadSlick() {

        var _this = this;
        $(this.sliderSelector).slick({
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 0,
                    settings: {
                        dots: true,
                        infinite: true,
                        autoplay: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: _this.initialSlide,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        dots: true,
                        infinite: true,
                        autoplay: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        arrows: true
                    }
                }
            ]
        });
    }
}






