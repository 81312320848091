export class BodyMapModule {
    constructor() {

        this.clickPainPoint();
    }

    clickPainPoint() {

        $(".bodypart-filter-attr").on("change", function (e) {

            e.preventDefault();
            $("#bodymap-content-area").addClass("loading");

            var bodyPartId = $(this).val();
            $.ajax({
                type: "GET",
                url: "/bodypartcoreapi/LoadBodyPart?key=" + bodyPartId,
                processData: false,
                contentType: false
            }).done(function (data) {

                setTimeout(function () {
                    $("#bodymap-content-defaultview").hide();
                    $("#bodymap-content-partview").html(data);
                    $("#bodymap-content-area").removeClass("loading");

                    $('html, body').animate({
                        scrollTop: $(".bodymap-content").offset().top - 90
                    });

                }, 1000);

            }).fail(function (xhr, status, error) {

                setTimeout(function () {
                    $("#bodymap-content-defaultview").show();
                    $("#bodymap-content-partview").html('');
                    $("#bodymap-content-area").removeClass("loading");

                    $('html, body').animate({
                        scrollTop: $(".bodymap-content").offset().top - 90
                    });

                }, 1000);

            });

        });


        $('.bodymap-item').on("click", function (e) {

            e.preventDefault();
            $("#bodymap-content-area").addClass("loading");

            var bodyPartId = $(this).attr("data-bodyitem-id");
            $.ajax({
                type: "GET",
                url: "/bodypartcoreapi/LoadBodyPart?key=" + bodyPartId,
                processData: false,
                contentType: false
            }).done(function (data) {

                setTimeout(function () {
                    $("#bodymap-content-defaultview").hide();
                    $("#bodymap-content-partview").html(data);
                    $("#bodymap-content-area").removeClass("loading");

                    $('html, body').animate({
                        scrollTop: $(".bodymap-content").offset().top - 90
                    });

                }, 1000);

            }).fail(function (xhr, status, error) {

                setTimeout(function () {
                    $("#bodymap-content-defaultview").show();
                    $("#bodymap-content-partview").html('');
                    $("#bodymap-content-area").removeClass("loading");

                    $('html, body').animate({
                        scrollTop: $(".bodymap-content").offset().top - 90
                    });

                }, 1000);

            });

        });

    }
}