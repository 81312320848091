export class SectionTextModule {
  
    constructor() {
        this.init();
    }

    init() {
        $('.sectiontext ul').filter(function () {
            return $(this).children("li").length > 4
        }).addClass('twoColumns');
    }
  
}