import { URLSearchParams } from "url";
import { UrlHelperModule } from "../helpers/urlHelper";

export class RatingsListModule {

    constructor() {
        if ($("#component-customer-ratings").length > 0) {
            this.init();
        }
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    gethospitals() {
        var _this = this;
        var r_baseUrl = "/ratingscoreapi/GetRatingsHospitals";
        var data = '';

        var hospitalName = document.getElementById("hospitalName").value;
        hospitalName = hospitalName.toLowerCase().replace("hospital", "").replace("spire", "");
        console.log(hospitalName);
        hospitalName = hospitalName.substring(0, 10).trim();
        console.log(hospitalName);

        $.ajax({
            type: 'get',
            data: data,
            url: r_baseUrl,
            dataType: "json",
            success: function (response) {

                var hasHospital = false;
                var items = response;
                var options = '<option value="">All hospitals</option>';
                items.forEach(element => {
                    if (hospitalName.length != 0 && element.Hospital.toLowerCase().includes(hospitalName)) {
                        options += "<option selected='selected' value='" + element.HospitalCode + "'>" + element.Hospital + "</option>";
                        hasHospital = true;
                    }
                    else {
                        options += "<option value='" + element.HospitalCode + "'>" + element.Hospital + "</option>";
                    }
                });

                $(".js-customer-ratings--hospital").html(options);

                if (hasHospital) {
                    var filter = $(".js-customer-ratings--hospital option:selected").val();
                    _this.getResults(filter);
                }
                else {
                   _this.getResults(filter);
                }
            }
        });

        $(".js-customer-ratings--hospital").change(function () {
           var filter = $(".js-customer-ratings--hospital option:selected").val();
           _this.getResults(filter);
        });
        
    }

    getResults(filter) {
        var _this = this;
        var seed = document.getElementById("hdnSeed").value;
        var r_baseUrl = "/ratingscoreapi/GetRatings?filter=" + filter + "&seed=" + seed;
        var data = filter;
        var dataContainer = $(".js-customer-ratings--list");
        var listingContainer = $(dataContainer).find(".customer-ratings--list--results");
        var output = "";
        //'TotalRows'

        $(dataContainer).pagination({
            dataSource: r_baseUrl,
            ajax: {
                contentType: null
            },
            locator: 'Rows',
            totalNumberLocator: function (response) {
                return response.TotalRows;
            },
            prevText: 'Previous',
            nextText: 'Next',
            pageSize: 10,
            callback: function (data, pagination) {
                output = "";
                data.forEach(element => {

                    var i;
                    var stars = '';
                    for (i = 0; i < 5; i++) {
                        if (i < element.RatingVal) {
                            stars += '<span class="starRating--star checked"></span>'
                        }
                        else {
                            stars += '<span class="starRating--star"></span>'
                        }
                    }

                    var comment = `
                        <div class='comment'>
                            <div>
                                <span class='rating'>
                                    <div class="starRating">
                                        ${stars}
                                    </div>
                                </span>
                                <span class='headline'>${element.Headline}</span>
                            </div>                            
                            <div class='reason'>${element.Reason}</div>
                            
                        </div>
                    `
                    output += comment;

                    //output += "<div class='comment'>" + element.Headline + "</div>";
                });

                listingContainer.html(output);
            }
        });

        //$(dataContainer).pagination({
        //    dataSource: function (done) {
        //        $.ajax({
        //            type: 'get',
        //            data: data,
        //            url: r_baseUrl,
        //            dataType: "json",
        //            success: function (response) {
        //                done(response.Rows);
        //            }
        //        });
        //    },
        //    locator: 'rows',
        //    totalNumber: 120,
        //    pageSize: 10,
        //    callback: function (data, pagination) {
        //        data.forEach(element => {
        //            output += "<div>" + element.Headline + "</div>";
        //        });

        //        listingContainer.html(output);
        //    }
        //});


        //$.ajax({
        //    type: 'get',
        //    data: data,
        //    url: r_baseUrl,
        //    dataType: "json",
        //    success: function (response) {

        //        console.log(response);

        //    }
        //});



        //$('#js-customer-ratings--list').pagination({
        //    dataSource: r_baseUrl,
        //    locator: 'rows',
        //    totalNumberLocator: function (response) {
        //        return Math.floor(math.random() * (1000 - 100)) + 100;
        //    },
        //    pageSize: 10,
        //    ajax: {
        //        //beforeSend: function () {
        //        //    dataContainer.html('Loading Data');
        //        //}
        //    },
        //    callback: function (data, pagination) {
        //        // template method of yourself
        //        var html = template(data);
        //        dataContainer.html(html);
        //    }
        //})


    }

    init() {

        //this.gethospitals();
        this.urlHelper = new UrlHelperModule();
        this.getResults();
    }

}