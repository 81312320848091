export class DownloadsListModule {
    constructor() {

        if ($(".downloadslist").length > 0) {
            this.init();
        }
    }

    setup() {

        var $el, $ps, $up, $p, totalHeight;


        $("#sort-order").on("change", function (e) {
            console.log(location.pathname);
            window.location = location.pathname + "?sort=" + $(this).val();
        });

        $(".read-more a").on("click", function (e) {
            totalHeight = 0;

            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            $ps = $up.find("p:not('.read-more')");

            $ps.each(function () {
                totalHeight += $(this).outerHeight();
            });

            $up
                .css({
                    "height": $up.height(),
                    "max-height": 9999
                })
                .animate({
                    "height": totalHeight + 40
                });

            $p.fadeOut();
            $up.find('.read-less').fadeIn();

            return false;
        });

        $(".read-less a").on("click", function (e) {

            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            totalHeight = $up.height();

            $up
                .css({
                    "height": totalHeight,
                    "max-height": 9999
                })
                .animate({
                    "height": 80
                });

            $p.fadeOut();
            $up.find('.read-more').fadeIn();

            return false;
        });

        var shownItems = 10;
        $(".download-item").slice(0, shownItems).show();

        $("#show-more").on('click', function (e) {
            e.preventDefault();
            $(".download-item:hidden").slice(0, shownItems).slideDown();
            if ($(".download-item:hidden").length == 0) {
                $("#show-more").fadeOut('slow');
            }
            $(".download-item-description").each(function () {
                var $el = $(this);
                if ($el.height() < 80) {
                    $el.find('.read-more').hide();
                }
                else {
                    if ($el.height() == 80) {
                        $el.find('.read-more').show();
                    }
                    else {
                        $el.find('.read-more').hide();
                        $el.find('.read-less').show();
                    }
                }
            });
        });

        $(".download-item-description").each(function () {
            var $el = $(this);
            if ($el.height() < 80) {
                $el.find('.read-more').hide();
            }
            else {
                $el.find('.read-more').show();
            }
        });
        if ($(".download-item:hidden").length == 0) {
            $("#show-more").hide();
        }
    }

    init() {
        this.setup();
    }

}